.footer{
  color: #fff;
  background-color: #313747;
  min-height: 79px;
  padding: 19px 39px 17px 31px;
  font-size: 12px;
  &-company{
    color: #fff;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.footer-container{
  max-width: 1110px;
  margin: 0 auto;
}
.footer-nav{
  &__first-row{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  a{
    color: #fff;
    margin-right: 2rem;
  }
  a:last-of-type{
    margin-right: 0;
  }
}
.footer-company:hover{
  text-decoration: none;
  color: #fff;
}


@media (max-width: 1024px) {
  .footer{
    padding: 16px 31px 16px 24px;
    min-height: 63px;
    font-size: 8px;
    font-weight: 300;
    letter-spacing: 0.09em;
    &-company{
      font-size: 18px;
    }
    .footer-nav__first-row{
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }
}
@media (max-width: 830px) {
  .footer{
    padding: 11px 23px 12px 18px;
    min-height: 47px;
    font-size: 8px;
    font-weight: 300;
    letter-spacing: 0.09em;

    &-company{
      font-size: 12px;
    }
  }
  .footer-nav a{
    margin-right: 1rem;
  }
}

@media (max-width: 767px) {
  .footer{
    font-size: 10px;
    &-company{
      font-size: 22px;
    }
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .footer-nav a{
    margin-right: 1.5rem;
  }
}
