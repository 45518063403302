.header_lined{
  position: relative;
}
.header_lined::after{
  content: '';
  height: 1px;
  background-color: #E0E0E0;
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  z-index: -1;
}
.section-header{
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.section-header_lined{
  display: inline-block;
  padding-left: 96px;
  padding-right: 96px;
  background-color: #fff;
}
.main-header{
  font-size: 28px;
  font-weight: bold;
  color: #313747;
}
.sub-header{
  font-size: 20px;
  color: #313747;
  font-weight: 300;
}


@media (max-width: 1024px) {
  .section-header{
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .section-header{
    font-size: 12px;
  }
  .section-header_lined{
    padding-left: 28px;
    padding-right: 28px;
  }
}
