.btn:focus,.btn:active {
   outline: none !important;
   box-shadow: none !important;
}

.btn-xlg{
  font-size: 18px;
  padding: 8px 24px;
  min-width: 218px;
  border-radius: 4px;
}

.btn{
  border: none;
  outline: none;
  padding-left: 25px;
  padding-right: 25px;
}

.btn-primary{
  color: #fff;
  &:hover{
    background-color: $primary-hover;
  }
  &:active{
    background-color: $primary-hover;
  }
}

.btn-secondary{
  color: #fff;
  &:hover{
    color: #fff;
  }
  &:not(:disabled):not(.disabled):active:active{
    color: #fff;
  }
}

a.btn-info:not([href]):not([tabindex]) {
    color: #fff;
}

.btn-info{
  color: #fff;
  &:hover{
    color: #fff;
  }
}

.btn-info:not(:disabled):not(.disabled):active{
  color: #fff;
}

.btn-danger{
  color: #fff;
}

.talk-btn{
  padding: 10px 60px;
  font-size: 24px;
}

.select-file-button{
  background: #C1B2D4;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
  padding: 1px 15px;
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer;
}


@media (max-width: 1024px) {
  .btn-xlg{
    min-width: 196px;
  }
}

@media (max-width: 768px) {
  .btn-xlg{
    font-size: 12px;
    padding: 7px 24px;
    min-width: 147px;
  }
}

@media (max-width: 576px) {
  .btn-xlg{
    font-size: 10px;
    padding: 6px 24px;
    min-width: 120px;
    border-radius: 2px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .btn-xlg{
    font-size: 10px;
    padding: 6px 24px;
    min-width: 120px;
  }
}
