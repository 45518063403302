$card-color: #313747;


.work-page .main-header{
  margin-top: 1em;
}
.workflow{
  max-width: 1022px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 34px auto 39px;
}
.workflow-start{
  max-width: 406px;
  width: 100%;
  padding: 17px 19px;
  background: rgba(196, 196, 196, 0.15);
}

.workflow-promises{
  max-width: 406px;
  width: 100%;
  padding: 17px 19px;
  background: rgba(196, 196, 196, 0.15);
}

.promises-list{
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  font-size: 16px;
  padding: 37px 31px;
  list-style: none;
  color: #484C57;
  line-height: 28px;
  font-weight: 500;
  min-height: 100%;
}

.workflow__arrow{
  position: relative;
  .vertical-line{
    height: 90%;
  }
  .swc-arrow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);
  }
}

.vertical-line{
  height: 100%;
  width: 1px;
  background-color: #E0E0E0;
}

.swc-arrow{
  background: url('/images/arrow.png') 0 0 no-repeat;
  background-size: contain;
  width: 128px;
  height: 80px;
}

.staff-section{

}
.staff-grid{
  max-width: 992px;
  margin: 0 auto;
  margin-top: 39px;
  .staff-card{
    margin-bottom: 30px;
  }
}
.staff-card{
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 42px 13px 13px 42px;
  font-size: 16px;
  color: $card-color;

  &__header{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
  }
  &__text{
    max-width: 390px;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  &__responsibilities{
    list-style: none;
    padding-left: 0;
  }
  &__more{
    color: $card-color;
    text-decoration: underline;
    font-weight: 500;
    &:hover{
      color: $card-color;
    }
  }

  &__salary{
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(150deg, transparent, transparent 31%, #F1E5FF 31%, #F1E5FF 100%);
    padding: 3px 9px;
  }
  &__salary-from{
    font-size: 1.25em;
    margin-right: 1.25em;
  }
  &__salary-to{
    font-weight: bold;
    font-size: 1.5em;
  }
}

.read-with-us{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  padding: 44px 20px 37px;
  &__header{
    font-size: 26px;
  }
  &__text{
    max-width: 360px;
  }
}

.work-page-contacts{
  margin-top: 20px;
  .contacts-block{
    margin: 35px auto 60px;
  }
}
