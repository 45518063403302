/* contacts-section */

.contacts-block{
  border: 22px solid #F2F2F2;
  width: 100%;
  max-width: 996px;
  display: flex;
  padding: 38px 61px 31px 40px;
}

.contacts-block-container{
  padding-left: 15px;
  padding-right: 15px;
}

.contacts-column{
  border-right: 1px solid #E0E0E0;
  padding-right: 38px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contacts-header{
  margin-left: 19px;
  font-size: 30px;
  margin-bottom: 18px;
}
.contacts-header{
  font-size: 48px;
  line-height: 56px;
  color: #313747;
  font-weight: 700;
  margin-bottom: 28px;
}
.contacts-list{
  list-style: none;
  padding-left: 0;
}
.contacts-item{
  padding: 22px 10px;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  &__header{
    font-size: 16px;
    color: #313747;
    font-weight: 500;
  }
  &__value{
    font-size: 14px;
      color: #313747;
    line-height: 24px;
  }
}
.contacts-item.contacts-item-control{
  padding: 0;
}

.contacts-list .contacts-item{
  margin-bottom: 10px;
}
.contacts-list .contacts-item:last-of-type{
  margin-bottom: 0;
}

.about-column{
  padding-left: 65px;
  width: 50%;
}
.contacts-form-control input, .contacts-form-control textarea{
  width: 100%;
}
//end


@media (max-width: 1024px) {
  .contacts-block{
    border-width: 20px;
    padding: 34px 53px 27px 35px;
    max-width: 811px;
  }

  .contacts-header{
    font-size: 26px;
    line-height: normal;
    margin-bottom: 16px;
  }
  .contacts-item{
    padding: 17px 9px;
    &__header{
      font-size: 14px;
    }
    &__value{
      font-size: 12px;
      line-height: 19px;
    }
  }
  .contacts-column{
    padding-right: 34px;
  }
  .about-column{
    padding-left: 57px;
    &__button{
      min-width: 170px;
      padding: 6px 28px;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .contacts-block{
    border-width: 16px;
    padding: 27px 42px 21px 28px;
    max-width: 657px;
  }

  .contacts-header{
    font-size: 20px;
    margin-bottom: 12px;
  }
  .contacts-item{
    padding: 13px 6px;
    &__header{
      font-size: 12px;
    }
    &__value{
      font-size: 10px;
      line-height: 16px;
    }
  }
  .contacts-column{
    padding-right: 26px;
  }
  .about-column{
    padding-left: 45px;
    &__button{
      min-width: 133px;
      padding: 6px 28px;
      font-size: 10px;
    }
  }
}

@media (max-width: 576px) {
  .contacts-block{
    border-width: 12px;
    padding: 20px 32px 16px 21px;
    max-width: 520px;
  }

  .contacts-header{
    font-size: 16px;
    margin-bottom: 12px;
  }
  .contacts-item{
    padding: 9px 5px;
    &__header{
      font-size: 12px;
      margin-bottom: 9px;
    }
  }
  .contacts-column{
    padding-right: 19px;
    .company-name{
      font-size: 16px;
    }
  }
  .about-column{
    padding-left: 30px;
    &__button{
      min-width: 103px;
      padding: 4px 15px;
      font-size: 9px;
      border-radius: 2px;
    }
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .contacts-block{
    padding: 17px 10px;
    flex-direction: column;
  }
  .contacts-column{
    width: 100%;
    border-right: none;
    padding-right: 0;
    .company-name{
      display: none;
    }
  }
  .contacts-header{
    text-align: center;
    margin-left: 0;
    margin-bottom: 8px;
  }
  .about-column{
    width: 100%;
    padding-left: 0;
    &__button{
      min-width: 137px;
      padding: 7px 20px;
      font-size: 10px;
      border-radius: 2px;
    }
  }
}
