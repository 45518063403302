@font-face {
    font-family: 'GothaPro';
    src: url('/fonts/GothaProReg.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'GothaPro';
    src: url('/fonts/GothaProBol.otf');
    font-weight: bold;
}
@font-face {
  font-family: 'GothaPro';
  src: url("/fonts/GothaProMed.otf");
  font-weight: 500;
}
@font-face {
  font-family: 'GothaPro';
  src: url("/fonts/GothaProLig.otf");
  font-weight: 300;
}

$primary-hover: #73C4FA;
$primary-active: #469FDB;
$header-color: #313747;

h1,h2,h3,h4,h5,h6{
  color: $header-color;
}

.swc-paragraph-2{
  font-size: 16px;
  line-height: 28px;
}

.list-dashed{
  li:before {
    content:  "-";
    position: relative;
    left:     -5px;
  }
}

.need-validation:invalid button[type='submit']{
  pointer-events: none;
  background-color: #BDBDBD;
}




@media (max-width: 1024px) {
  .swc-paragraph-2{
    font-size: 12px;
    line-height: 23px;
  }
}

@media (max-width: 768px) {
  .swc-paragraph-2{
    font-size: 10px;
    line-height: 17px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .swc-paragraph-2{
    font-size: 8px;
    line-height: 14px;
  }
}
