//represent-section
.represent-section{
  margin: 0 auto;
  max-width: 1170px;
  padding: 33px 30px 67px;
  &__description{
    padding-top: 130px;
    width: 50%;
  }
  &__h-main{
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
  }
  &__h-sub{
    font-size: 20px;
    margin-top: 15px;
    font-weight: 300;
  }
  &__image{
    margin-left: auto;
    width: 50%;
  }
}
//end


/* clients block */
.clients-block{
  padding: 30px 30px 22px;
  background: #F5F5F5;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  .section-header{
    font-size: 20px;
  }
}
.clients-block__container{
  margin: 0 auto;
}
.clients-block__info{
  color: #787D86;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.clients{
  max-width: 848px;
  margin: 23px auto 20px;
}
.clients-row{
  border-bottom: 2px solid #E4E4E4;
  width: 100%;
}
.clients-row:last-of-type{
  border-bottom: none;
}
.clients-row__container{
  max-width: 1112px;
  margin: 0 auto;
  display: flex;
}

.client-item{
  padding: 15px;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-right: 2px solid #E4E4E4;
}
.client-item img{
  max-width: 100%;
  max-height: 100%;
}
.client-item:last-of-type{
  border-right: none;
}
.clients-block .clients{
  margin-top: 47px;
  margin-bottom: 47px;
}
// end


/* underus-block */
.underus-block{
  margin-top: 86px;
  &__image{
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 687px;
    width: 513px;
    margin-right: 63px;
  }
  &__info{
    padding-top: 70px;
  }
  &__mockup-carousel{
    height: 687px;
  }
  &__text-carousel{
    width: 474px;
  }
}
.carousel-wrapper{
  position: relative;
}
.carousel-gradient{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to top, #fff, transparent 30%);
  z-index: 2;
}
.carousel-control{
  cursor: pointer;
  outline: none;
}
.carousel-controls{
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 25px;
  color: #313747;
  .carousel-control-prev{
    margin-right: 67px;
  }
}
.icon-link:hover{
  text-decoration: none;
}
/* end */

//contacts section
.contacts-section{
  margin-top: 60px;
  margin-bottom: 90px;
}

.contacts-section .contacts-block{
  margin: 100px auto 0;
}
//end

//final-text-section
.final-text-section{
  margin-bottom: 90px;
}
//end

//with-us-section
.with-us-section{
  padding: 20px 30px;
  box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.15);
  &__text-header{
    font-size: 24px;
  }
  &__text{
    width: 58%;
  }
  .swc-paragraph-2{
    font-size: 14px;
    line-height: 22px;
  }
}
.with-us-container{
  margin: 0 auto;
  max-width: 1110px;
}
.btn_with-us{
  font-size: 14px;
  padding: 8px 50px;
  min-width: 186px;
}
.left-block{
  border-right: 1px solid #EDEDED;
}
.left-block, .right-block{
  padding: 37px 0;
  width: 50%;
}
//end

@media (max-width: 1280px) {
  .underus-block{
    &__image{
      width: 342px;
      height: 458px;
      margin-right: 11px;
    }
    &__mockup-carousel{
      height: 458px;
    }
    &__text-carousel{
      width: 288px;
    }
    &__info{
      padding-top: 5px;
    }
  }
  .carousel-controls{
    margin-bottom: 13px;
    margin-top: 0;
    font-size: 24px;
    .carousel-control-prev{
      margin-right: 50px;
    }
  }
  .contacts-section{
    .contacts-block{
      margin-top: 48px;
    }
  }
}

@media (max-width: 1024px) {
  .represent-section{
    padding-bottom: 53px;
    &__h-main{
      font-size: 24px;
      line-height: 37px;
    }
    &__h-sub{
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .clients-block{
    padding: 25px 30px 22px;
    .section-header{
      font-size: 16px;
    }
    .clients{
      margin-top: 18px;
      margin-bottom: 20px;
      max-width: 770px;
    }
    &__info{
      font-size: 12px;
    }
  }
  .client-item{
    height: 88px;
  }

  .underus-block{
    margin-top: 38px;
    &__image{
      width: 311px;
      height: 416px;
      margin-right: 9px;
    }
    &__mockup-carousel{
      height: 416px;
    }
    &__text-carousel{
      width: 216px;
    }
    &__info{
      padding-top: 5px;
    }
  }

  .contacts-section{
    margin-bottom: 44px;
    .contacts-block{
      margin-top: 44px;
    }
  }
  .final-text-section{
    margin-bottom: 38px;
  }

  .with-us-section{
    padding: 24px 42px 18px;
    .swc-paragraph-2{
      font-size: 12px;
      line-height: 19px;
    }
    &__text-header{
      font-size: 20px;
      margin-bottom: 5px;
    }
    .left-block, .right-block{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .btn_with-us{
    font-size: 12px;
    padding: 6px 20px;
    min-width: 149px;
  }
}

@media (max-width: 900px) {
  .with-us-section{
    padding: 21px 30px 16px;
    .swc-paragraph-2{
      font-size: 10px;
      line-height: 15px;
    }
    &__text-header{
      font-size: 14px;
    }
  }
  .btn_with-us{
    font-size: 10px;
    padding: 4px 20px;
    min-width: 111px;
  }
}

@media (max-width: 768px) {
  .represent-section{
    padding: 29px 0 43px 35px;
    &__h-main{
      font-size: 20px;
      line-height: 28px;
    }
    &__h-sub{
      font-size: 12px;
      margin-top: 7px;
    }
  }

  .clients-block{
    padding: 13px 30px 17px;
    .section-header{
      font-size: 12px;
    }
    .clients{
      margin-top: 13px;
      margin-bottom: 13px;
      max-width: 577px;
    }
    &__info{
      font-size: 10px;
    }
  }
  .client-item{
    height: 88px;
  }

  .underus-block{
    margin-top: 32px;
    &__image{
      width: 241px;
      height: 322px;
      margin-right: 7px;
    }
    &__mockup-carousel{
      height: 322px;
    }
    &__text-carousel{
      width: 191px;
    }
    &__header{
      font-size: 26px;
    }
  }
  .carousel-controls{
    font-size: 18px;
    .carousel-control-prev{
      margin-right: 30px;
    }
  }

  .contacts-section{
    margin-bottom: 23px;
    .contacts-block{
      margin-top: 17px;
    }
  }
  .final-text-section{
    margin-bottom: 38px;
  }

  .with-us-section{
    padding: 20px 31px 16px;
    .swc-paragraph-2{
      font-size: 10px;
      line-height: 15px;
    }
    &__text-header{
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  .btn_with-us{
    font-weight: 300;
    font-size: 10px;
    padding: 5px 20px;
    min-width: 111px;
  }
}

@media (max-width: 576px) {
  .represent-section{
    padding: 29px 0 33px 32px;
    &__h-main{
      font-size: 16px;
      line-height: 24px;
    }
    &__description{
      padding-top: 80px;
    }
  }

  .clients-block{
    padding: 12px 30px 13px;
  }
  .client-item{
    height: 55px;
    padding: 8px;
  }

  .underus-block{
    margin-top: 26px;
    &__header{
      font-size: 22px;
    }
  }

  .contacts-section{
    margin-bottom: 17px;
    margin-top: 20px;
    .contacts-block{
      margin-top: 15px;
    }
  }

  .final-text-section{
    margin-bottom: 18px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {

  .represent-section{
    padding: 16px 0;
    &__h-main{
      font-size: 16px;
      line-height: 24px;
    }
    &__h-sub{
      display: none;
    }
    &__description{
      padding-top: 0;
      margin-bottom: 16px;
      width: 100%;
      text-align: center;
    }
    &__image{
      width: 100%;
    }
  }

  .clients-block{
    padding: 12px 30px 13px;
  }
  .clients-row{
    &__container{
      flex-wrap: wrap;
    }
  }
  .client-item{
    width: 50%;
    height: 60px;
    padding: 6px;
    border-top: 2px solid #E4E4E4;
  }
  .client-item:nth-child(2n){
    border-right: none;
  }
  .client-item:nth-child(1), .client-item:nth-child(2){
    border-top: none;
  }

  .underus-block{
    margin-top: 20px;
    &__image{
      width: 152px;
      height: 203px;
      margin-right: 5px;
    }
    &__mockup-carousel{
      height: 203px;
    }
    &__text-carousel{
      width: 140px;
    }
    &__header{
      font-size: 16px;
    }
  }
  .carousel-controls{
    font-size: 15px;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .with-us-section{
    padding: 18px 13px 16px;
    .swc-paragraph-2{
      font-size: 8px;
      line-height: 13px;
    }
    &__text-header{
      font-size: 10px;
      margin-bottom: 5px;
    }
    &__text{
      width: 95%;
    }
  }
  .left-block{
    padding-right: 10px;
  }
  .right-block{
    padding-left: 10px;
  }
  .btn_with-us{
    font-weight: 300;
    font-size: 10px;
    padding: 5px 20px;
    min-width: 111px;
    border-radius: 2px;
  }
}
