.company-name{
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  color: $header-color;
  &:hover{
    text-decoration: none;
    color: $header-color;
  }
}
.nav-container{
  margin: 0 auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.swc-navbar{
  height: 117px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 2px solid #EDEDED;
  .navbar-nav{
    margin-right: 30px;
    min-width: 580px;
    flex-direction: row;
  }
  &__btn{
    padding: 10px 55px;
    font-size: 18px;
    border-radius: 5px;
  }
}

.swc-navbar .navbar-nav .nav-item{
  padding-left: 15px;
  padding-right: 15px;
  &:last-of-type{
    margin-right: 0;
  }
}

.swc-nav-link{
  color: #000;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 0;
  &:hover{
    color: #000;
    text-decoration: none;
  }
}

.swc-nav-link::after{
  display: inline-block;
  content: "";
  height: 5px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -17px;
  width: 0%;
  background-color: #5DB3ED;
  transition: .3s all;
}

.swc-nav-link:hover::after{
  width: 100%;
}

.swc-nav-link_info::after{
  background-color: $info;
}



@media (max-width: 1024px) {
  .swc-navbar{
    .navbar-nav{
      margin-right: 30px;
      min-width: 500px;
    }
    &btn{
      min-width: 196px;
      padding: 9px 20px;
      font-size: 16px;
    }
  }
  .company-name{
    font-size: 32px;
  }
  .swc-nav-link{
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .swc-navbar{
    padding-top: 16px;
    padding-bottom: 17px;
    height: auto;
    .nav-container{
      flex-direction: column;
      justify-content: center;
    }
    .navbar-nav{
      margin-right: 0;
      min-width: 500px;
    }
    &__btn{
      display: none;
    }
  }
  .company-name{
    margin-bottom: 15px;
  }
  .swc-nav-link::after{
    bottom: -9px;
    height: 3px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .swc-navbar{
    padding: 0 11px 10px;
    .navbar-nav{
      min-width: 100%;
    }
  }
  .swc-nav-link{
    font-size: 8px;
    line-height: 12px;
  }
  .company-name {
    font-size: 16px;
    line-height: normal;
  }
  .swc-nav-link::after{
    bottom: -5px;
    height: 2px;
  }
  .swc-navbar .navbar-nav .nav-item{
    padding-left: 3px;
    padding-right: 3px;
    line-height: normal;
  }
}
