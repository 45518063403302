$placeholder-color: #9E9E9E;
$placeholder-color-bordered: #919191;

.swc-input-text{
  border: none;
  background-color: inherit;
  outline: none;
  font-size: 14px;
  padding: 10px 22px;
  color: #313747;
  resize: none;

  &::-webkit-input-placeholder {color:$placeholder-color;}
  &::-moz-placeholder          {color:$placeholder-color;}/* Firefox 19+ */
  &:-moz-placeholder           {color:$placeholder-color;}/* Firefox 18- */
  &:-ms-input-placeholder      {color:$placeholder-color;}
}



.swc-control_border{
  font-size: 16px;
  padding: 10px 22px;
  color: $placeholder-color-bordered;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  resize: none;

}

select.swc-control_border {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.swc-select-container{
  position: relative;
  &::after{
    transform: translateY(-50%);
    display: inline-block;
    font-family: Icons;
    color: $placeholder-color-bordered;
    content: '\e034';
    position: absolute;
    width: 1em;
    top: 50%;
    right: 27px;
  }
}


@media (max-width: 1024px) {
  .swc-input-text{
    font-size: 12px;
    padding: 9px 17px;
  }
}
@media (max-width: 768px) {
  .swc-input-text{
    font-size: 10px;
    padding: 8px 13px;
  }
}
@media (max-width: 576px) {
}
