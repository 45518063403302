// Overwrite bootstrap variables
@import "./variables.scss";

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/buttons";

// our styles
@import "./styles.scss";
@import "./buttons.scss";
@import "./controls.scss";
@import "./headers.scss";
@import "./navbar.scss";
@import "./footer.scss";
@import "./common-sections.scss";
@import "./home-page.scss";
@import "./work-page.scss";
